import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
export default [
  {
    name: indexkP7wmsbr11Meta?.name ?? "index",
    path: indexkP7wmsbr11Meta?.path ?? "/",
    meta: indexkP7wmsbr11Meta || {},
    alias: indexkP7wmsbr11Meta?.alias || [],
    redirect: indexkP7wmsbr11Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  }
]