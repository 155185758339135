import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
	Sentry.init({
		app: nuxtApp.vueApp,
		dsn: 'https://3ac31209b85e4d558d270ccf0b99bec8@o562637.ingest.sentry.io/4505464277893120',
	})

	nuxtApp.vueApp.use(Sentry)
})
