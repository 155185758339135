import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// const myTheme: ThemeDefinition = {
// 	colors: {
// 		primary: '#3f51b5',
// 	},
// }

export default defineNuxtPlugin((nuxtApp) => {
	const vuetify = createVuetify({
		components,
		directives,
		theme: {
			defaultTheme: 'light',
		},
	})

	nuxtApp.vueApp.use(vuetify)
})
